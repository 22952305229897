var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "d-flex align-center full-width ma-0 banner-wrap position-relative",
                  class: _vm.$vuetify.breakpoint.smAndUp
                    ? ""
                    : "flex-column-reverse",
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "px-sm-6 pr-xl-12 pl-0 pr-md-0 px-0 banner-text-wrap pt-sm-0",
                      attrs: { cols: "10", sm: "8", md: "6", lg: "4" },
                    },
                    [
                      _c(
                        "h1",
                        { staticClass: "pt-0 font-weight-black text-h4" },
                        [_vm._v(_vm._s(_vm.tradeInHeading.header))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-subtitle-1 font-weight-medium pt-6",
                        },
                        [_vm._v(_vm._s(_vm.tradeInHeading.desc))]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 img-wrap justify-center",
                      class: _vm.$vuetify.breakpoint.smAndUp
                        ? "d-flex"
                        : "d-none",
                      attrs: { cols: "3", sm: "3", md: "4", lg: "3" },
                    },
                    [
                      _c("img", {
                        style: "width: " + _vm.bannerWidth + "%",
                        attrs: {
                          src: _vm.tradeInHeading.src,
                          alt: _vm.tradeInHeading.alt,
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "d-flex align-center full-width ma-0 pb-6 pt-0 pt-sm-6 why-trade-in",
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "pa-0 text-center text-sm-left text-md-right",
                      attrs: { cols: "8", sm: "3", md: "2", lg: "2", xl: "1" },
                    },
                    [
                      _c("img", {
                        class: _vm.$vuetify.breakpoint.xs
                          ? "rotate-landscape"
                          : "",
                        style: "width: " + _vm.tradeInImgWidth + "%",
                        attrs: {
                          src: _vm.whyTradeIn.src,
                          alt: _vm.whyTradeIn.alt,
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "px-5 pl-sm-12 pr-sm-0",
                      attrs: { cols: "12", sm: "7", md: "8", lg: "6", xl: "6" },
                    },
                    [
                      _c("h2", { staticClass: "font-weight-bold text-h4" }, [
                        _vm._v(_vm._s(_vm.whyTradeIn.header)),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-subtitle-1 font-italic font-weight-light pt-lg-4",
                        },
                        [_vm._v(_vm._s(_vm.whyTradeIn.desc))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-h5 font-italic font-weight-bold khaki--text py-4 text-capitalize",
                        },
                        [_vm._v(_vm._s(_vm.whyTradeIn.subtitle))]
                      ),
                      _c(
                        "ul",
                        _vm._l(_vm.whyTradeIn.steps, function (step, index) {
                          return _c(
                            "li",
                            {
                              key: "How to Trade In " + index,
                              staticClass: "d-flex flex-nowrap align-center",
                            },
                            [
                              _c("p", { staticClass: "d-block mr-2" }, [
                                _vm._v(_vm._s(index + 1)),
                              ]),
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "d-flex text-subtitle-1 font-italic font-weight-bold",
                                  class: _vm.$vuetify.breakpoint.xl
                                    ? "seventy-width"
                                    : "full-width",
                                },
                                [_vm._v(_vm._s(step))]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "d-flex align-center full-width ma-0 things-to-do",
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pt-6",
                      attrs: { cols: "11", sm: "11", md: "10", lg: "7" },
                    },
                    [
                      _c("h2", { staticClass: "font-weight-bold text-h4" }, [
                        _vm._v(_vm._s(_vm.thingsToDo.header)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "content-wrap d-flex justify-center pa-0",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "12",
                            md: "12",
                            lg: "8",
                            xl: "7",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-subtitle-1 font-italic desc font-weight-light pt-4",
                            },
                            [_vm._v(_vm._s(_vm.thingsToDo.desc))]
                          ),
                          _c(
                            "ul",
                            { staticClass: "d-flex py-4 px-0 justify-center" },
                            _vm._l(_vm.thingsToDo.steps, function (step) {
                              return _c(
                                "li",
                                {
                                  key: step.alt,
                                  staticClass:
                                    "d-flex flex-column align-center",
                                  class:
                                    _vm.$vuetify.breakpoint.xs ||
                                    _vm.$vuetify.breakpoint.xl
                                      ? "xs-li"
                                      : "",
                                },
                                [
                                  _c("img", {
                                    attrs: { src: step.img, alt: step.alt },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-subtitle-1 font-weight-normal",
                                    },
                                    [_vm._v(_vm._s(step.desc))]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "d-flex align-center full-width ma-0 py-6 faqs",
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pt-6",
                      attrs: { cols: "11", sm: "11", md: "10", lg: "7" },
                    },
                    [
                      _c("h2", { staticClass: "font-weight-bold text-h4" }, [
                        _vm._v(_vm._s(_vm.faqs.header)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "content-wrap d-flex justify-center pa-0",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-0",
                          class: _vm.$vuetify.breakpoint.xs ? "pt-0" : "",
                          attrs: {
                            cols: "11",
                            sm: "11",
                            md: "10",
                            lg: "7",
                            xl: "7",
                          },
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "pl-0" },
                            _vm._l(_vm.faqs.content, function (faq) {
                              return _c("li", { key: faq.header }, [
                                _c(
                                  "h4",
                                  {
                                    staticClass:
                                      "py-3 text-subtitle-2 font-weight-bold",
                                  },
                                  [_vm._v(_vm._s(faq.header))]
                                ),
                                _c("div", {
                                  staticClass:
                                    "text-subtitle-2 font-weight-normal pb-3",
                                  domProps: { innerHTML: _vm._s(faq.desc) },
                                }),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "d-flex align-center full-width ma-0 py-6 tncs",
                  class: _vm.$vuetify.breakpoint.xs ? "pt-0" : "",
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pt-6",
                      attrs: { cols: "11", sm: "11", md: "10", lg: "7" },
                    },
                    [
                      _c("h2", { staticClass: "font-weight-bold text-h4" }, [
                        _vm._v(_vm._s(_vm.tnc.header)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "content-wrap d-flex justify-center pa-0",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-0 pl-sm-5 pt-3",
                          attrs: {
                            cols: "11",
                            sm: "11",
                            md: "10",
                            lg: "7",
                            xl: "7",
                          },
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "pl-6 pa-sm-0" },
                            _vm._l(_vm.tnc.content, function (tnc) {
                              return _c(
                                "li",
                                {
                                  key: tnc,
                                  staticClass:
                                    "text-subtitle-2 font-weight-normal pb-3",
                                },
                                [_vm._v(" " + _vm._s(tnc) + " ")]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }