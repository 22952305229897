<template>
  <div>
    <v-row justify="center" no-gutters>
      <v-col cols="12">
        <v-row justify="center" class="d-flex align-center full-width ma-0 banner-wrap position-relative" :class="$vuetify.breakpoint.smAndUp ? '' : 'flex-column-reverse'">
          <v-col cols="10" sm="8" md="6" lg="4" class="px-sm-6 pr-xl-12 pl-0 pr-md-0 px-0 banner-text-wrap pt-sm-0">
            <h1 class="pt-0 font-weight-black text-h4">{{ tradeInHeading.header }}</h1>
            <div class="text-subtitle-1 font-weight-medium pt-6">{{ tradeInHeading.desc }}</div>
          </v-col>
          <v-col cols="3" sm="3" md="4" lg="3" class="pa-0 img-wrap justify-center" :class="$vuetify.breakpoint.smAndUp ? 'd-flex' : 'd-none'">
            <img :src="tradeInHeading.src" :alt="tradeInHeading.alt" :style="'width: ' + bannerWidth + '%'" />
          </v-col>
        </v-row>

        <v-row justify="center" class="d-flex align-center full-width ma-0 pb-6 pt-0 pt-sm-6 why-trade-in">
          <v-col cols="8" sm="3" md="2" lg="2" xl="1" class="pa-0 text-center text-sm-left text-md-right">
            <img :src="whyTradeIn.src" :alt="whyTradeIn.alt" :style="'width: ' + tradeInImgWidth + '%'" :class="$vuetify.breakpoint.xs ? 'rotate-landscape' : ''" />
          </v-col>
          <v-col cols="12" sm="7" md="8" lg="6" xl="6" class="px-5 pl-sm-12 pr-sm-0">
            <h2 class="font-weight-bold text-h4">{{ whyTradeIn.header }}</h2>
            <div class="text-subtitle-1 font-italic font-weight-light pt-lg-4">{{ whyTradeIn.desc }}</div>
            <div class="text-h5 font-italic font-weight-bold khaki--text py-4 text-capitalize">{{ whyTradeIn.subtitle }}</div>
            <ul>
              <li v-for="(step, index) in whyTradeIn.steps" :key="'How to Trade In ' + index" class="d-flex flex-nowrap align-center">
                <p class="d-block mr-2">{{ index + 1 }}</p>
                <h5 class="d-flex text-subtitle-1 font-italic font-weight-bold" :class="$vuetify.breakpoint.xl ? 'seventy-width' : 'full-width'">{{ step }}</h5>
              </li>
            </ul>
          </v-col>
        </v-row>

        <v-row justify="center" class="d-flex align-center full-width ma-0 things-to-do">
          <v-col cols="11" sm="11" md="10" lg="7" class="pa-0 pt-6">
            <h2 class="font-weight-bold text-h4">{{ thingsToDo.header }}</h2>
          </v-col>
          <v-col cols="12" class="content-wrap d-flex justify-center pa-0">
            <v-col cols="12" sm="12" md="12" lg="8" xl="7">
              <div class="text-subtitle-1 font-italic desc font-weight-light pt-4">{{ thingsToDo.desc }}</div>
              <ul class="d-flex py-4 px-0 justify-center">
                <li v-for="step in thingsToDo.steps" :key="step.alt" class="d-flex flex-column align-center" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.xl ? 'xs-li' : ''">
                  <img :src="step.img" :alt="step.alt" />
                  <div class="text-subtitle-1 font-weight-normal">{{ step.desc }}</div>
                </li>
              </ul>
            </v-col>
          </v-col>
        </v-row>

        <v-row justify="center" class="d-flex align-center full-width ma-0 py-6 faqs">
          <v-col cols="11" sm="11" md="10" lg="7" class="pa-0 pt-6">
            <h2 class="font-weight-bold text-h4">{{ faqs.header }}</h2>
          </v-col>
          <v-col cols="12" class="content-wrap d-flex justify-center pa-0">
            <v-col cols="11" sm="11" md="10" lg="7" xl="7" class="pl-0" :class="$vuetify.breakpoint.xs ? 'pt-0' : ''">
              <ul class="pl-0">
                <li v-for="faq in faqs.content" :key="faq.header">
                  <h4 class="py-3 text-subtitle-2 font-weight-bold">{{ faq.header }}</h4>
                  <div class="text-subtitle-2 font-weight-normal pb-3" v-html="faq.desc"></div>
                </li>
              </ul>
            </v-col>
          </v-col>
        </v-row>

        <v-row justify="center" class="d-flex align-center full-width ma-0 py-6 tncs" :class="$vuetify.breakpoint.xs ? 'pt-0' : ''">
          <v-col cols="11" sm="11" md="10" lg="7" class="pa-0 pt-6">
            <h2 class="font-weight-bold text-h4">{{ tnc.header }}</h2>
          </v-col>
          <v-col cols="12" class="content-wrap d-flex justify-center pa-0">
            <v-col cols="11" sm="11" md="10" lg="7" xl="7" class="pl-0 pl-sm-5 pt-3">
              <ul class="pl-6 pa-sm-0">
                <li v-for="tnc in tnc.content" :key="tnc" class="text-subtitle-2 font-weight-normal pb-3">
                  {{ tnc }}
                </li>
              </ul>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TradeIn',
  data() {
    return {
      tradeInHeading: {
        header: 'UNBOX makes it Easy to Trade In!',
        desc: "Simply walk into the UNBOX Outlet. Allow the Service Crew to inspect your device. If you're eligible for reward credits, you may immediately purchase with rebates! Otherwise, we offer proper recycling/disposal services for your beloved old device.",
        src: '/static/images/tradeIn/banner-img.png?',
        srcXs: '/static/images/tradeIn/banner-img-xs.png?',
        alt: 'smartphone Trade In'
      },
      whyTradeIn: {
        header: 'Why Trade In?',
        desc: 'The last thing your old device can do for you, is earn credits for a new one or be recycled for the planet.',
        src: '/static/images/tradeIn/trade-in-img.jpg',
        alt: 'Trade In smartphone image',
        subtitle: 'How Trade In works?',
        steps: ['Bring it to the UNBOX outlet.', 'See how much your device is worth.', 'Earn credits to immediately knock off your purchase price or let us recycle it for you.']
      },
      thingsToDo: {
        header: 'Things To Do?',
        desc: "Simply walk into the UNBOX outlet and we'll handle the rest.",
        steps: [
          { img: '/static/images/tradeIn/ttd-01.png', alt: 'outlet icon', desc: 'Walk into our Outlet' },
          { img: '/static/images/tradeIn/ttd-02.png', alt: 'value icon', desc: 'Get it valued and start thinking of your new phone' },
          { img: '/static/images/tradeIn/ttd-03.png', alt: 'transfer service icon', desc: "We'll handle the rest including data transfer & migration" }
        ]
      },
      faqs: {
        header: 'Frequently Asked Questions',
        img: '/static/images/tradeIn/faqs.png',
        alt: 'faqs title',
        content: [
          {
            header: 'How does Trade In work?',
            desc: "UNBOX welcomes you to Trade In your devices. Our friendly service crew will inspect your device to estimate its worth. If you're eligible for credits, UNBOX will allow you to instantly use it.<br/><br/>Complimentary data transfer & migration services will be provided by UNBOX Service Crew in-store if you purchase a new smartphone in-store and Trade In an old one."
          },
          {
            header: 'Which devices can I Trade In',
            desc: 'Any smartphone or tablet can be brought in as long as it can be powered on and is unlocked. A higher Trade In credit value depends on the condition and how recent it was released.'
          }
        ]
      },
      tnc: {
        header: 'Terms & Conditions *',
        img: '/static/images/tradeIn/tnc.png',
        alt: 'term and conditions title',
        content: [
          'The inspection can only be performed in-store by the UNBOX Service Crew.',
          'UNBOX reserves the right to inspect all devices thoroughly before accepting them for Trade In.',
          'UNBOX will determine the price for devices according to hardware defects etc., after the inspection.',
          'The Trade In device must be able to be powered on and unlocked for inspection. (e.g., No lockage of phone, Google ID, Find My)',
          'Customers may Trade In their device to purchase any item from the Outlet as long as there is value in the Trade In.',
          'No refunds/reimbursements will be provided if the value of the Trade In is more than the item being purchased.',
          'Original boxes and cables are not mandatory for Customers to provide, but you might earn a higher valuation of your old devices if provided.',
          'UNBOX reserves the right to amend the terms and conditions at any time without prior notice.',
          'For more information, simply ask our friendly Service Crew for help.'
        ]
      }
    }
  },
  computed: {
    bannerWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 300
        case 'sm':
          return 110
        case 'md':
          return 81
        case 'lg':
          return 65
        case 'xl':
          return 45
      }
      return 110
    },
    tradeInImgWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 80
        case 'sm':
          return 110
        case 'md':
          return 115
        case 'lg':
          return 80
        case 'xl':
          return 115
      }
      return 80
    }
  },
  methods: {
    goToExternalLink(type) {
      var link = ''
      if (type == 'splitPay') {
        link = 'https://bit.ly/unboxsngl'
      } else if (type == 'whatsapp') {
        link = 'https://wa.me/+60178080375'
      } else if (type == 'dashboard') {
        link = 'http://dashboard.savewithsplit.co/'
      }
      window.open(link, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.position-relative {
  position: relative;
}

.banner-wrap {
  background: linear-gradient(90deg, #f9fafc, #e1e1ef);

  .reverse-img {
    // transform: rotate(-45deg);
    position: absolute;
    right: 25%;
    top: 0;
  }
}

li {
  list-style: none;
}

.why-trade-in {
  .rotate-landscape {
    transform: rotate(90deg);
  }

  ul {
    padding: 0;
  }

  p {
    background: black;
    color: white;
    font-weight: bold;
    border-radius: 50%;
    width: 30px !important;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
  h5 {
    border-bottom: dashed 2px black;
    padding-bottom: 10px;
  }
}

.things-to-do {
  background: #f1f3f4;

  .desc {
    text-align: center;
  }

  .xs-li {
    width: 40%;

    div {
      width: 90%;
    }
  }

  li {
    width: 30%;

    img {
      width: 50%;
    }

    div {
      width: 60%;
      text-align: center;
    }
  }
}

.faqs {
  img {
    width: 100%;
  }
}

.tncs {
  background: #e7e8ea;

  img {
    width: 100%;
  }

  li {
    list-style: disc;
  }
}
</style>
